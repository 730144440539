<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td>
            <div class="brand-checkbox my-auto mx-auto">
                <input class="" :checked="checked" type="checkbox" :id="`ch-${template.id}`"/>
                <label :for="`ch-${template.id}`"  @click="$emit('select')"></label>
            </div>
        </td>
        <td v-text="template.id"></td>

        <td class="mr-auto" v-text="template.name"></td>
        <td class="mr-auto" v-text="template.system"></td>
        <td v-text="template.description"></td>
        <td v-text="template.tour_count"></td>
        <td>
            <div class="my-auto mx-auto">
                <router-link :to="{name: 'template', params: {id: template.id}}"  title="Редактировать шаблон" class="">
                    <font-awesome-icon class="text-black text-base hover:bg-grey" :icon="['fas', 'edit']"></font-awesome-icon>
                </router-link>
            </div>
        </td>
        <td v-if="isParty || isWom || isCorp || isLive || isFamily">
            <font-awesome-icon v-if="on_loading" class="spinner w-6 my-auto mx-auto text-black text-base" :icon="['fas', 'spinner']"></font-awesome-icon>
            <div v-else class="w-64">
                <button @click.prevent="generate"
                        class="text-white text-xs no-underline p-1 my-auto mr-1 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                    Сформировать
                </button>
                <button @click.prevent="refill('game-regenerate')"
                        class="text-white text-xs no-underline p-1 my-auto mt-2 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                    Пересобрать игры (случайные+строгие)
                </button>
                <button @click.prevent="refill('game-static-regenerate')"
                        class="text-white text-xs no-underline p-1 my-auto mt-2 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                    Пересобрать только строгие
                </button>
               <button @click.prevent="refill('game-random-regenerate')"
                       class="text-white text-xs no-underline p-1 my-auto mt-2 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                 Пересобрать только случайные
               </button>
            </div>
        </td>
        <td @click="copy_open = true"><div class="w-4"><img src="@/icons/copy-regular.svg" alt=""></div></td>
        <td class="border-r-0 ">
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="remove(template.id)">
                <trash></trash>
            </a>
        </td>
        <CopyModal @close="copy_open = false" v-if="copy_open" :id="this.template.id" @updateData="$emit('updated')" />
    </tr>

</template>

<script>
import Trash from '../../icons/trash'
import axios from 'axios'
import swal from 'sweetalert2'
import CopyModal from './copy-modal'

export default {
  components: { Trash, CopyModal },
  name: 'GameTemplate',
  props: ['template', 'checked', 'isParty', 'isWom', 'isCorp', 'isLive', 'isFamily'],
  data () {
    return {
      on_loading: false,
      city_id: '',
      error_message: '',
      copy_open: false,

    }
  },
  methods: {
    remove (id) {
      this.$emit('remove', [id])
    },
    generate () {
      this.on_loading = true
      axios.get(`/api/game/generate?template_id=${this.template.id}&city_id=${this.city_id}`).then((data) => {
        this.on_loading = false
        swal('Успех!', 'Игра успешно сгенерирована', 'success')
        this.$router.push({ name: 'game', params: { id: data.data.id } })
      }).catch((error) => {
        this.on_loading = false
        if (error.response.status === 500) { this.error_message = error.response.data; swal('Ошибка!', this.error_message, 'error') }
      })
    },
    refill (methodName) {
      this.on_loading = true
      axios.post(`/api/templates/${this.template.id}/${methodName}`).then((data) => {
        this.on_loading = false
        swal('Успех!', 'Игры будут пересобраны', 'success')
      }).catch((error) => {
        this.on_loading = false
        if (error.response.status === 500) { this.error_message = error.response.data; swal('Ошибка!', this.error_message, 'error') }
      })
    },
    copyTemplate () {
      this.$emit('openCopy', this.template.id)
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-grey-light;
    }

    .spinner {
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
